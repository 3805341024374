@import url(https://fonts.googleapis.com/css?family=Abel|Francois+One|News+Cycle|Yanone+Kaffeesatz&display=swap);
.line::-webkit-scrollbar {
  width: 4px;
}

.ui.circular.label {
  padding: 0 !important;
  font-size: 1.15em;
  margin: 0.1rem;
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid rgba(208, 212, 216, 0.15)
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

